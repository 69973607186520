<template>
  <div class="container-fluid">
    <div class="page-header mb-1">
      <strong>ตั้งค่าเกม - แบ่งหุ้น/คอมฯ</strong>
    </div>
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="cat in catItems" :key="cat._id" class="nav-item">
            <a class="nav-link" :class="[{'active':catId===cat._id}]" href="#" target="_self" @click="catId=cat._id">{{ cat.name }}</a>
          </li>
        </ul>
      </div>

      <div class="p-1">
        <button
          class="btn btn-sm m-1"
          v-for="item in creatorItems"
          :key="item._id"
          :class="[{'btn-info': item._id===creId}, {'btn-outline-info': item._id!=creId}]"
          @click="creId=item._id"
        >{{ item.name }}</button>
      </div>

      <div class="px-2">
        <table class="table table-bordered table-striped table-sm table-info table-hover mb-2">
          <thead>
            <tr v-if="creId" class="bg-light">
              <th colspan="8" class="text-center font-weight-bold text-primary p-3">ค่ายเกม {{creatorName}}</th>
            </tr>
            <tr>
              <th rowspan="2" width="60">ลำดับ</th>
              <th width="60">เลือก</th>
              <th>ชื่อใช้งาน</th>
              <th>ระดับ</th>
              <th width="180">สถานะ</th>
              <th width="180">แบ่งหุ้น</th>
              <th width="180">คอมฯ</th>
              <th width="60"></th>
            </tr>
            <tr v-if="items.length" class="alert-warning">
              <td class="text-center">
                <b-form-checkbox v-model="checkAll" class="m-0" :value="true" :unchecked-value="false" />
              </td>
              <td colspan="2" class="text-center small">ตั้งค่ากับที่เลือก</td>
              <td class="text-left pl-3">
                <b-form-radio-group
                  id="bulk-status"
                  v-model="bulkData.isAvailable"
                  :options="statusOptions"
                  name="bulk-status"
                  stacked
                ></b-form-radio-group>
              </td>
              <td class="align-top text-left">
                <b-form-checkbox
                  v-model="checkedShareHolder"
                  :value="true"
                  :unchecked-value="false"
                >
                  ตั้งค่า
                </b-form-checkbox>
                <b-form-select
                  v-model="bulkData.shareHolder.receive"
                  :disabled="!bulkAccountIds.length || !checkedShareHolder"
                  :options="shareOptions"
                  size="sm"
                ></b-form-select>
              </td>
              <td class="align-top text-left">
                <b-form-checkbox
                  v-model="checkedCommission"
                  :value="true"
                  :unchecked-value="false"
                >
                  ตั้งค่า
                </b-form-checkbox>
                <b-form-input
                  size="sm"
                  v-model="bulkData.shareHolder.commission"
                  :disabled="!bulkAccountIds.length || !checkedCommission"
                  type="number"
                  :number="true"
                >
                </b-form-input>
              </td>
              <td class="align-top">
                <button @click="saveBulk" :disabled="!bulkAccountIds.length" class="btn btn-sm btn-success">บันทึก</button>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item.accountId">
              <td>{{ index+1 }}</td>
              <td>
                <b-form-checkbox
                  v-model="bulkAccountIds"
                  :value="item.accountId"
                />
              </td>
              <td>{{ item.user.username }}</td>
              <td>{{ item.level.levelName }}</td>
              <td>
                <b-form-checkbox v-model="item.gameSettings[creId].isAvailable" name="check-button" switch @change="toggleChange(item)" :class="[item.gameSettings[creId].isAvailable ? 'text-success' : 'text-danger']">
                  {{ item.gameSettings[creId].isAvailable ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
              <td>
                <span v-if="item.gameSettings[creId].isAvailable">{{ item.gameSettings[creId].shareHolder.receive | amountFormat(2, '0.00') }}</span>
                <i v-else class="fas fa-times text-secondary"></i>
              </td>
              <td>
                <span v-if="item.gameSettings[creId].isAvailable">{{ item.gameSettings[creId].shareHolder.commission | amountFormat(2, '0.00') }}</span>
                <i v-else class="fas fa-times text-secondary"></i>
              </td>
              <td>
                <button :disabled="!item.catSettings[catId]" class="btn btn-sm" :class="[item.catSettings[catId] ? 'btn-outline-primary' : 'btn-outline-secondary']" @click="editCreator(item)"><i class="far fa-edit"></i></button>
              </td>
            </tr>
            <tr v-if="!items.length">
              <td class="text-center" colspan="8">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <SetGameModal
      :account="account"
      :cat-id="catId"
      :categories="catItems"
      :creators="creators"
      :is-show="isShowModal"
      @reload="loadAccounts"
      @close="modalClosed"
    />
  </div>
</template>
<script>
import GameService from '@/services/gameService'
import Swal from 'sweetalert2'
import SetGameModal from './components/SetGameModal'

export default {
  name: 'UserSettingHolderGame',
  components: {
    SetGameModal
  },
  data() {
    return {
      categories: [],
      creators: [],
      catId: null,
      creId: null,
      accounts: [],
      isShowModal: false,
      account: null,
      checkAll: false,
      bulkAccountIds: [],
      bulkData: {
        isAvailable: null,
        shareHolder: {
          receive: 100,
          referral: 0,
          over: false,
          commission: 0
        }
      },
      checkedShareHolder: false,
      checkedCommission: false
    }
  },
  computed: {
    catItems() {
      return this.categories.filter((cat)=>{
        return cat.status === 'Active'
      })
    },
    creatorItems() {
      const creators = this.creators.filter((cre)=>{
        return cre.status === 'Active' && cre.catId === this.catId
      })

      if(creators.length) {
        this.creId = creators[0]._id
      }else{
        this.creId = null
      }

      return creators
    },
    items() {
      return this.accounts.filter((acc)=>{
        return this.creId && acc.isAvailable && acc.catSettings[this.catId]
      })
    },
    creator() {
      return this.creators.find((cre)=>{
        return cre._id === this.creId
      })
    },
    creatorName() {
      return this.creator?.name
    },
    statusOptions() {
      return [
        { text: 'ไม่ตั้งค่า', value: null },
        { text: 'เปิด', value: true },
        { text: 'ปิด', value: false }
      ]
    },
    shareOptions() {
      let sharePercents = []
      for(let i=100; i>=0; i-=0.5) {
        sharePercents.push({
          text: i.toFixed(1),
          value: i
        })
      }
      return sharePercents
    },
    changeTab() {
      return { catId: this.catId, creId: this.creId }
    }
  },
  watch: {
    checkAll(n, o) {
      this.bulkAccountIds = []
      if(n){
        for(const item of this.items) {
          // if(item.gameSettings[this.creId].isAvailable) {
            this.bulkAccountIds.push(item.accountId)
          // }
        }
      }
    },
    changeTab() {
      this.reset()
    }
  },
  methods: {
    loadCats() {
      GameService.getCreators().then((response)=>{
        if(response.success) {
          this.categories = response.data.categories
          this.creators = response.data.creators

          if(this.categories.length) {
            this.catId = this.categories[0]._id
          }
        }
      })
    },
    loadAccounts(accountId=null) {
      GameService.gameAccounts().then((response)=>{
        if(response.success) {
          this.accounts = response.data

          if(accountId) {
            this.account = this.accounts.find((a)=>{
              return a.accountId === accountId
            })
          }
        }
      })
    },
    toggleChange(account) {
      const updateData = {
        accountIds: [account.accountId],
        update: [this.creId].reduce((obj, creId)=>{
          obj[creId] = {
            isAvailable: account.gameSettings[this.creId].isAvailable
          }
          return obj
        }, {})
      }
      this.updateCreator(updateData)
    },
    saveBulk() {

      let updateData = {}
      if(this.bulkData.isAvailable !== null) {
        updateData['isAvailable'] = this.bulkData.isAvailable
      }

      if(this.checkedShareHolder || this.checkedCommission) {
        updateData['shareHolder'] = {}

        if(this.checkedShareHolder) {
          updateData['shareHolder']['receive'] = this.bulkData.shareHolder.receive
          updateData['shareHolder']['referral'] = (100 - this.bulkData.shareHolder.receive)
        }

        if(this.checkedCommission) {
          updateData['shareHolder']['commission'] = this.bulkData.shareHolder.commission || 0
        }
      }

      if(!Object.keys(updateData).length)
        return

      const data = {
        accountIds: this.bulkAccountIds,
        update: [this.creId].reduce((obj, creId)=>{
          obj[creId] = updateData
          return obj
        }, {})
      }

      this.updateCreator(data)
    },
    updateCreator(data) {
      GameService.updateCreatorSettings(data)
      .then((response)=>{
        if(!response.success) {
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ไม่สำเร็จ!',
          text: e.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.reset()
        this.loadAccounts()
      })
    },
    editCreator(account) {
      this.account = account
      this.isShowModal = true
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadAccounts()
      }
    },
    reset() {
      this.checkAll = false
      this.bulkAccountIds = []
      this.checkedShareHolder = false
      this.checkedCommission = false
      this.bulkData = {
        isAvailable: null,
        shareHolder: {
          receive: 100,
          referral: 0,
          over: false,
          commission: 0
        }
      }
    }
  },
  mounted() {
    this.loadCats()
    this.loadAccounts()
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th, td {
        text-align: center;
        font-weight: normal;
        vertical-align: middle;
      }

      th:first-child {
        // border-left: 0;
      }

      th:last-child {
        // border-right: 0;
      }
    }
  }

  tbody {
    background-color: #FFFFFF;
    tr {
      td {
        vertical-align: middle;
        text-align: center;
      }

      td:first-child {
        // border-left: 0;
      }

      td:last-child {
        // border-right: 0;
      }
    }
  }
}
</style>
