import api from "@/services/apiService"

class GameService {
  saveCategory(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'game/category', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateCategory(_id, data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'game/category/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  getCategories() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'game/category')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  saveCreator(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'game/creator', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateCreator(_id, data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'game/creator/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  getCreators() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'game/creator')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  getGames() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'game')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateStatus(data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'game/active', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  saveGame(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'game', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateGame(_id, data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'game/' + _id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  gameAccounts() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'game/account')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  // updateAccsCats(data) {
  //   return new Promise((resolve, reject) => {
  //     api.put(process.env.VUE_APP_BACKEND_API + 'game/accounts-categories', data)
  //     .then(function(response) {
  //       resolve(response.data)
  //     })
  //     .catch(function(error) {
  //       reject(error)
  //     })
  //   })
  // }

  // updateAccsGames(data) {
  //   return new Promise((resolve, reject) => {
  //     api.put(process.env.VUE_APP_BACKEND_API + 'game/accounts-games', data)
  //     .then(function(response) {
  //       resolve(response.data)
  //     })
  //     .catch(function(error) {
  //       reject(error)
  //     })
  //   })
  // }

  updateGameAvailable(data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'game/game-available', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateCreatorSettings(data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_BACKEND_API + 'game/creator-settings', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  gameAccount(accountId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'game/account/' + accountId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  deposit(id, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'game/deposit/' + id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }

  withdraw(id, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'game/withdraw/' + id, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      });
    });
  }
}

export default new GameService()
